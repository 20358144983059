<template>
  <div>
    <b-row>
      <b-col
        lg="7"
        md="6"
      >
        <CardCongratulation :class="!isSimmiUser ? 'simi-user-class' : null" />
      </b-col>
      <b-col
        lg="5"
        md="6"
      >
        <card-account-settings />
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CardCongratulation from '@/common/components/CardCongratulation.vue'
import CardAccountSettings from '@/components/account-setting/CardAccountSettings.vue'

export default {
  components: {
    BRow,
    BCol,
    CardCongratulation,
    CardAccountSettings,
  },
  computed: {
    isSimmiUser() {
      return this.$store.getters['user/isSimmiUser']
    },
  },
}
</script>
<style scoped>
.simi-user-class{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
